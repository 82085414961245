import * as React from "react"
import IndexWindmill from "../components/animations/IndexWindmill"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function IndexPageEn(props) {
  const lang = props.pageContext.langKey

  return (
    <div>
      <Layout isHome lang={lang}>
        <Seo title="Home" />
        <div className="h-full w-full relative">
          <div className="w-full h-full">
            <div className="absolute h-full w-full ">
              <IndexWindmill />
            </div>
            <div className="relative h-full w-full flex flex-col flex-wrap items-center md:justify-center pt-16 lg:pt-0 z-50">
              <div className="container font-semibold intro-text text-xl md:text-2xl ">
                <div className="leading-relaxed">
                  <p>
                    Bodecker Partners expertise is in the Nordic Power markets
                    is unique. We offer independent advisory services and
                    management of merchant market risks to investors and asset
                    owners of Nordic renewable power production as well as PPA
                    advisory to industry sourcing sustainable renewable
                    electricity.
                  </p>
                  <p>
                    Bodecker Partners – truly independent advice and revenue
                    risk management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
